var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("base-input-container " + (_vm.isNotValid ? 'is-danger' : ''))},[(_vm.label)?_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.label)+" ")]):_vm._e(),((_vm.type)==='checkbox')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.baseInputValue),expression:"baseInputValue"}],class:[
      _vm.customSize === 'large' ? 'large' : '',
      _vm.customSize === 'medium' ? 'medium' : '',
      _vm.customSize === 'xlarge' ? 'xlarge' : '',
      _vm.customSize === 'small' ? 'small' : '',
      _vm.customSize === 'thin' ? 'thin' : '',
      'base-input',
      _vm.value !== '' ? 'hideDangerIcon' : 'showDangerIcon' ],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"required":_vm.required,"max":_vm.max,"maxlength":_vm.maxlength,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.baseInputValue)?_vm._i(_vm.baseInputValue,null)>-1:(_vm.baseInputValue)},on:{"change":function($event){var $$a=_vm.baseInputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.baseInputValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.baseInputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.baseInputValue=$$c}}}},'input',_vm.$attrs,false)):((_vm.type)==='radio')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.baseInputValue),expression:"baseInputValue"}],class:[
      _vm.customSize === 'large' ? 'large' : '',
      _vm.customSize === 'medium' ? 'medium' : '',
      _vm.customSize === 'xlarge' ? 'xlarge' : '',
      _vm.customSize === 'small' ? 'small' : '',
      _vm.customSize === 'thin' ? 'thin' : '',
      'base-input',
      _vm.value !== '' ? 'hideDangerIcon' : 'showDangerIcon' ],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"required":_vm.required,"max":_vm.max,"maxlength":_vm.maxlength,"type":"radio"},domProps:{"checked":_vm._q(_vm.baseInputValue,null)},on:{"change":function($event){_vm.baseInputValue=null}}},'input',_vm.$attrs,false)):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.baseInputValue),expression:"baseInputValue"}],class:[
      _vm.customSize === 'large' ? 'large' : '',
      _vm.customSize === 'medium' ? 'medium' : '',
      _vm.customSize === 'xlarge' ? 'xlarge' : '',
      _vm.customSize === 'small' ? 'small' : '',
      _vm.customSize === 'thin' ? 'thin' : '',
      'base-input',
      _vm.value !== '' ? 'hideDangerIcon' : 'showDangerIcon' ],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"required":_vm.required,"max":_vm.max,"maxlength":_vm.maxlength,"type":_vm.type},domProps:{"value":(_vm.baseInputValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.baseInputValue=$event.target.value}}},'input',_vm.$attrs,false))])}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("baseDatePickerContainer " + (_vm.isNotValid ? 'is-danger' : ''))},[(_vm.label)?_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('b-form-datepicker',_vm._b({class:[
      _vm.size === 'large'
        ? 'base-datePicker large'
        : _vm.size === 'medium'
        ? 'base-datePicker medium'
        : 'base-datePicker small' ],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"icon":"calendar-today","locale":"en","date-format-options":{
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      weekday: 'short',
    },"min":_vm.min,"max":_vm.max,"unselectable-dates":_vm.unselectableDates,"unselectable-days-of-week":_vm.unselectableDaysOfWeek,"events":_vm.events,"indicators":_vm.indicators},on:{"input":_vm.updateValue},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},'b-form-datepicker',_vm.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }